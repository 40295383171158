.user-report-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.user-report-info{
    padding: 12px;
    background-color:#37373F ;
    border-radius: 10px;
}
.user-report-table-container{
    width: 100%;
    display: flex;
    gap: 4px;
}