.tab-panel-container {
    width: 100%;
}
.tab-panel-inputs{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.input-tab-group{
    width: 45%;
    display: flex;
    flex-direction: column;
}
.tab-panel-inputs-tab2{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.input-tab-group-tab2{
  display: flex;
  flex: 1 1 calc(50% - 10px); /* Each item takes 50% of the row, minus gap spacing */
  box-sizing: border-box;
}
.input-tab-group-tab2 label{
  
}

.input-tab-group label {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
  }
  
  .tab-input {
    width: 90%;
    border: 1px solid #bd904e;
    border-radius: 5px;
    padding:  10px;
    font-size: 16px;
    outline: none;
    background-color: #f9f9f9;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .input-dropdown {
    appearance: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNjY2NjY2IiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgMTYuNTkgMTEuMjkgMTZsLTYuMy02LjMgMS40MS0xLjQxIDQuODkgNC44OWw0Ljg5LTQuODkgMS40MSAxLjQxeiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 20px;
  }
  
  .input-dropdown:hover,
  .input-dropdown:focus {
    outline: 1px solid #aaa;
  }
  
  .input-dropdown option {
    color: #333;
    background-color: #ffffff;
  }
  /* ///////////////////////////////// */
  .tab-panel-emailreq-inputs{
    width: 100%;
    margin: 8px 0 0 0 ;
    padding: 8px 12px;
    background-color: #37373f;
    border-radius: 10px;
  }
  .tab-panel-requests-inputs{
    width: 100%;
    border-radius: 10px;
    padding: 10px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border: 1px solid #bd904e;
    margin: 10px 0 0 0;
  }

  .table-in-peicing-tab{
    width: 100%;
    margin-top: 10px;
  }