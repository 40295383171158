.table-allCallbacks-contaier{
    width: 100%;

}
.table-allCallbacks-filter-contaier{
    width: 100%;
    padding: 6px;
    background-color: #37373F;
    border-radius: 8px;
}

/* //////////////////////////////////////////////////////////// */
.allcallback-sales-manager-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.callback-sales-container{

}

.callback-sales-contain{
    width: 100%;
    padding: 14px 10px;
    border-radius: 8px;
    border: 1.5px solid #37373F;
    /* display: flex;
    flex-direction: column;
    gap: 8px; */
}