.not-found-container {
  width: 100%;
  height: 100vh;
  text-align: center;
  padding-top: 30px;
  background-color: #ffffff;
}

.not-found-title {
  font-size: 7rem;
  margin-bottom: 16px;
  color: #bd904e;
  font-weight: 700;
}
.not-found-message {
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: 700;
}
.not-found-link {
  color: #007bff;
  font-size: 1.2rem;
}

.not-found-link:hover {
    text-decoration: underline;
  }