.mainSection-container{
    height: 100vh;
    flex: 1;
    background: #1c1c1e;
    color: #ffffff;
    padding: 22px;
    overflow: auto
}
.mainSection-header-container{
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
}
.lettel-bar-text{
    font-size: 14px;
    padding: 10px 15px;
    letter-spacing: 1px;
}
.span-lettel-bar{
    text-transform: capitalize;
    color: antiquewhite;
    font-weight: 900;
}

.top-line{
    width: 100%;
    height: 0.8px;
    background-color: #aaa3a3;
    margin-top: 4px;
}
.bottom-line{
    width: 100%;
    height: 2px;
    background-color: #ffffff;
}
.mainSection-content-container{
    margin-top: 18px;
}

.lettel-bar-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-time-contanier{
    display: flex;
    align-items: center;
    
}

.lettel-bar-info-contain{
    display: flex;
    gap: 20px;
    align-items: center;
}
.lettel-bar-info-btn {
    background: #FFFFFF;
    color: #333;
    padding: 7px 18px;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: 1.3px;
    font-weight: 600;
}
.lettel-bar-info-btn:hover {
    background: #333; 
    color: #FFFFFF; 
    border: 1px solid #2196F3;
}