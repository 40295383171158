.create-raw-Leads-contanier{
    
}
.raw-Show-Create-container{
    width: 100%;
    min-height: 80vh;
    overflow: auto; 
}
.raw-Show-Create-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.raw-Show-details-container{
}