.register-user-contanier {
  margin: 25px;
}
.register-header {
  margin-bottom: 15px;
  letter-spacing: 2px;
}
.register-text {
  letter-spacing: 2px;
  color: #a9a2bd;
}

.register-form-contanier {
  margin-top: 35px;
}

.form-control-container {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  gap: 25px;
}
.inputs-form-controls,
.select-dropdown-control {
  width: 44%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputs-form-controls label,
.select-dropdown-control label {
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "Playfair Display", serif;
}
.inputs-form-controls input,
.dropdown-container {
  width: 100%;
  padding: 15px 20px;
  border: none;
  border: 1px solid #a093b8;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  color: black;
  background-color: #ffff;
  letter-spacing: 1px;
}
.inputs-form-controls input::placeholder {
  font-weight: 550;
}
.inputs-form-controls input:focus {
  color: #ffffff;
  background-color: #3b364c;
}

.dropdown-container {
  position: relative;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><polygon points="0,0 10,0 5,10" fill="%23a093b8"/></svg>'); /* Custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  transition: border 0.3s ease;
  cursor: pointer;
}

.buttons-form-controls {
  width: 44%;
  margin-top: 32px;
}
.buttons-form-controls .linear-button{
    width: 100%;
    padding: 15px 20px;
    border-radius: 10px;
    background: linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880);
    color: #ffffff;

}
.buttons-form-controls .button-text{
    font-size: 18px;
}
.buttons-form-controls .linear-button:hover{
    background: linear-gradient(180deg, #8b5a5a, #6c4c6e, #4a6880);

}
.linear-button:hover:disabled {
    background: #d3d3d3 !important; /* Light gray color */
    cursor: not-allowed !important; /* Change cursor to indicate it's disabled */
  }

/* Responsive Design */

/* For screens 1024px and below */

@media (max-width: 1200px) {
  .register-user-contanier {
    margin: 15px;
  }

  .inputs-form-controls,
  .select-dropdown-control , .buttons-form-controls{
    width: 48%;
  }
}

/* For screens 768px and below */
@media (max-width: 768px) {
  .register-user-contanier {
    margin: 15px;
  }

  .inputs-form-controls,
  .select-dropdown-control , .buttons-form-controls{
    width: 100%;
  }
}






/* 
const handleChangeRole = (event) => {
    setSelectedRole(event.target.value);
    setSelectedTimeZone("");
  };

  const handleChangeTimeZone = (event) => {
    setSelectedTimeZone(event.target.value);
  }; */

/*     
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState(""); */