.pricing-table-data-container{
    width: 98%;
    margin: auto;
}
.show-table-dialog-container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.dialog-header-close,.info-show-dialog,.hotel-table-dialog{
    width: 100%;
}