.Incomplete-Leads-contanier{
    margin: 10px 10px 0;
}
.Incomplete-Leads-header{
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: capitalize;
}
.Incomplete-Leads-filters{
    width: 100%;
    margin: 10px 0 15px;
    padding: 10px 20px;
    border-radius: 6px;
    display: flex;
    gap:45px;
    background-color: #37373f;
}

.update-leads-grid-contanier{
    display: flex;
    align-items: center;
}

.check-box-input{
    margin-right: 10px;
}
.check-box-label{
    letter-spacing: 1px;
}
.Incomplete-Leads-table-contanier{
    width: 100%;
    margin: 15px 0;
    background-color: #37373f;
}