.existing-leads-container {
  width: 100%;
}

.shift-select {
  width: 100%;
  padding: 10px 20px;
  background: #fff;
  margin-bottom: 18px;
  border-radius: 10px;
  margin-top: 20px !important;
}

.header-controllers {
  display: flex;
  align-items: center;
  gap: 3rem;
}
