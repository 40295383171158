.Located-shows-container{
    width: 100%;
}

.Located-shows-contain{
    width: 100%;
    display: grid;
    gap: 14px;
}

.Located-show-box{
    width: 100%;
    /* padding: 14px 20px; */
    border-radius: 10px;
    border: 1.5px solid #37373F;
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
    background-color:#fff;
    color: #333;
  }