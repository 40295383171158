.scraper-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 16px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .form-group input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .scrape-button {
    background-color: #007acc;
    color: white;
    padding: 10px 16px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }
  
  .scrape-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .error-msg {
    margin-top: 16px;
    color: red;
    text-align: center;
  }
  
  .results-container {
    margin-top: 24px;
  }
  
  .results-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .results-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .results-table th,
  .results-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .results-table th {
    background-color: #f0f0f0;
  }