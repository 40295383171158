.sales-manager-team-manager-section{
  width: 98%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sale-manager-date-header{

}
.sale-manager-date-header span{
  background: linear-gradient(to right, #fed766, #e8b6ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 2px;
}
.sales-manager-leads-count-container{
  width: 60%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-auto-rows: auto;
  gap: 15px;
}
.leads-count-box{
  padding: 10px 20px;
  background-color: #37373F;
  border-radius: 10px;
}
.leads-count-box-p{
  letter-spacing: 1px;
  font-size: 18px;
  color:#e8b6ea ;
  font-weight: 600;
}
.leads-count-box-p span{
  color:#e2e2e2 ;
  font-weight: 800;
}

.team-members-container{
  width: 100%;
  display: grid;
  gap: 14px;
}
.team-member-box{
  width: 100%;
  padding: 14px 20px;
  border-radius: 8px;
  border: 1.5px solid #37373F;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.team-member-box-info{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  grid-gap: 10px;

}
.team-member-box-buttons{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.team-member-box-buttons button{
  padding: 10px 20px;
  border-radius: 8px;
  background: linear-gradient(to right, #fed766, #e8b6ea);
color: #fff;
font-size: 16px;
letter-spacing: 1px;
}








@media (max-width: 1300px) {
  .sales-manager-leads-count-container {
    width: 100%;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
  }

  .team-member-box-info {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
  }
}

/* Small screens (mobile devices) */
@media (max-width: 768px) {
  
  .sales-manager-leads-count-container {
    width: 100%;
    grid-template-columns: 1fr; /* 1 column */
  }

  .team-member-box-info {
    grid-template-columns: 1fr; /* 1 column */
  }

  .sale-manager-date-header span {
    font-size: 20px; /* Adjusting text size for small screens */
  }

  .leads-count-box-p {
    font-size: 16px; /* Adjust font size for small screens */
  }

  .team-member-box {
    padding: 12px 18px; /* Adjust padding for small screens */
  }

  .team-member-box-info {
    gap: 10px; /* Reducing gap for small screens */
  }
}

/* Extra small screens (very small devices like older phones) */
@media (max-width: 480px) {
  .sales-manager-leads-count-container {
    width: 100%;
    grid-template-columns: 1fr; /* 1 column */
  }

  .sale-manager-date-header span {
    font-size: 18px; /* Further reducing text size */
  }

  .leads-count-box-p {
    font-size: 14px; /* Adjust font size */
  }

  .team-member-box {
    padding: 10px 16px; /* Further reducing padding */
  }

  .team-member-box-info {
    grid-template-columns: 1fr; /* 1 column */
    gap: 8px; /* Further reducing gap */
  }
}