.email-req-table-filters{
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    background-color: #37373F;
    display: flex;
    flex-wrap: wrap;
}
.search-email-req{
    flex: 1; /* Each child will take up an equal amount of space */
    margin: 0 10px;
}

.Dialog-card-Details{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.dialod-card-data-email{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.dialod-card-data-email p{
    flex: 1; /* Each child will take up an equal amount of space */
    margin: 0 10px 0 0;
}