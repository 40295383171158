.agent-member-histoy-container {
    width: 100%;
    height: 100vh;
    color: #FFF;
    display: flex;
}

.agent-member-histor-contain {
    padding: 15px;
    width: 95%;
    
}

.agent-member-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.agent-member-info-section, .agent-member-table-section{
    width: 100%;
    padding: 14px 20px;
}

.agent-member-filter-section
{
    width: 98%;
    margin: 0 auto ;
    padding: 10px 20px;
    border-radius: 8px;
    border: 2px solid #37373F;
    display: flex;
    gap: 25px;
    align-items: center;
    flex-wrap: wrap;

}

.agent-member-info-section {}

.agent-member-filter-section {}

.agent-member-table-section {}

.agent-member-info-section h2 span{
    background: linear-gradient(to right, #fed766, #e8b6ea);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: 700;
}

/* For medium-sized screens (tablets or larger) */
@media (max-width: 768px) {
    
}

/* For small screens (mobile) */
@media (max-width: 480px) {

}