.history-leads-agent-container{
    width: 100%;
}

.history-agent-filter{
    margin: 0 0 10px 0;
    background-color: #fff;
    padding:8px 20px;
    display: flex;
    gap: 20px;
    border-radius: 8px;
}

.history-agent-table-contanier{
    margin: 14px 0 0 0;
}
.span-liner-date{
    background: linear-gradient(to right, #fed766, #e8b6ea);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: 700;
}
.history-agent-grid-box{
    margin: 0 0 10px ;
}
.fliters-onAccordition{
    width: 20%;
}