.assigned-leads-filters-container{
    width: 100%;
    border-radius: 6px;
    padding: 8px 16px;
    border: 2px solid #37373F;
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.assigned-leads-table-container{
    width: 100%;
    margin: 18px 0 0 0;
}