.edition-years-filter-container{
    width: 100%;
    padding: 10px 20px;
    background:#fff;
    margin-bottom: 18px;
    border-radius: 10px;
}
.locator-table-container{
    width: 100%;
    max-height: 72vh;
    overflow: auto;
}