.Email-team-section{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Email-button-contain{
    display: flex;
    gap: 20px;
}
.Email-button-select{
    background: linear-gradient(#fed766,#e8b6ea);
    padding: 20px 40px;
    color: #fff;
    border-radius: 10px;
    font-size:18px;
    font-weight: 700;
}
.Email-button-select:hover{
    background: #fff;
    color: #333;
}


.email-request-page-contanier{
    margin: 20px 20px 0;
}
.email-request-page-header{
    margin-bottom: 15px;
    letter-spacing: 2px;
    text-transform: capitalize;
}

.email-request-table-container{
    background-color: #ffffff;
    padding: 15px 15px 5px;
    width: 100%;
    max-height: 85vh;
    border-radius: 15px;
    color: black;
}
.email-req-table-contain{
    margin: 15px 0;
    width: 100%;
}

.table-email-button{
    padding: 10px 20px;
    background-color: rgb(79, 99, 125);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}


.close-dialog-button{
    padding: 14px 35px;
    border-radius: 20px;
    background: linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880);
    color: #ffffff;
    font-size: 15px;
}

.close-dialog-button:hover{
    background:#4a6880;
}

.Dialog-card-container{
    width: 100%;
    /* width: fit-content; */
    margin: 14px;
    border-radius: 10px;
    border: 2px solid #efebeb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}
.Dialog-card-header{
    width: 100%;
    background: #fdf8f5;
    padding: 15px;
    border-radius: 10px 10px 0 0;
    display: flex;
    gap: 18px;
    color: #d44a00;
}
.Dialog-card-contain{
    padding: 14px 20px 14px 55px ;
}
.Dialog-card-Details{

}
.Dialog-card-Details p{
    font-size: 16px;
    font-weight: 550;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 10px;
}
.Dialog-card-Details p span{
    color: #6d707e;
    /* margin-right: 8px; */
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
}

.Dialog-feedback-buttons{
    margin: 0 20px;
}
.Dialog-feedback-buttons button{
    padding: 16px 30px;
    color: #ffffff;
    border-radius: 8px;
}

.Dialog-card-Changes{

}
.Dialog-card-Changes button{
    margin-top: 10px;
    font-size: 17px;
    background: none;
    padding: 6px 50px;
    border-radius: 15px;
    border: 1.5px solid #333;
}
.Dialog-card-Changes button:hover{
    background-color: #4a6880;
    color: #ffffff;
    border: none;
}

/* ////////////////////////////////////////////////////// */
.email-req-table-filters{
    /* background-color: #fff; */
    padding: 6px 12px;
    border-radius: 10px;
    display: flex;
    gap: 20px;
}
.test-new-req-button{
    padding: 6px 20px;
    border-radius: 10px;
    font-size: 16px;
}
.test-new-req-button:hover{
    background-color: #333;
    color: #ffffff;
}
.search-email-req{
    width: 22%;
}