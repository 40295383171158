.helth-check-contanier{
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img-helth-check-contain{
    width: 100%;
    display: flex;
    margin: 10px 0;
}
.img-helth-check-contain img{
    width: 70%;
    margin:0 auto;
    height: 60vh;
}