.sidebar-container {
  background: #37373f;
  color: #ffffff;
  width: 65px;
  height: 100vh;
  text-align: center;
  padding: 30px 6px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 0 15px 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border-right: 2px solid #bdc3c7; */
}
.sidebar-container.open {
  width: 200px;
  text-align: start;
  padding: 25px 18px;
  display: flex;
  flex-direction: column;
}
.menu-icon {
  font-size: 1.5rem;
  font-weight: bold;
  transform: scale(1.2);
  cursor: pointer;
}

.sidebar-content{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.buttons-container-main{
  overflow: auto;
  max-height:82%;
  margin-top: 15px;

}
.sidebar-buttons{
  background: linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880);
    color: #ffffff;
    width: 100%;
    padding: 15px 0;
    border: none;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 6px;
}

.sidebar-buttons:hover{
  background: linear-gradient(180deg, #8b5a5a, #6c4c6e, #81b6e27e);

}

.logout-container{
    /* height: 200px; */
    display: flex;
    align-items: flex-end;
}


.icon-container {
  position: relative;
}

.notification-dot {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  z-index: 10;
  pointer-events: none;
}
