.matching-Pricing-container {
    width: 100%;
  }
  
  .tables-matching-Pricing-section{
    width: 100%;
    max-height: 95vh;
    overflow: auto;
    background-color: #fff;
    padding: 5px 8px;
    border-radius: 8px;
  }
  
  .request-status-container {
    padding: 4px 6px;
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1.2px solid #333;
    gap: 18px;
    margin-bottom: 10px;
  }
  .request-status {
    margin-bottom: 5px;
    padding: 4px;
    border-radius: 5px;
  }
  
  .request-status.Waiting {
    background-color: #ffeb3b;
  }
  
  .request-status.Pending {
    background-color: #2196f3;
    color: white;
  }
  
  .request-status.Completed {
    background-color: #4caf50;
    color: white;
  }
  
  .request-status.Failed {
    background-color: #f44336;
    color: white;
  }
  
  .matching-button-container {
    margin: 10px 0;
    width: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: flex-end;
  }
  .matching-linear-button {
    padding: 8px 20px;
    border-radius: 8px;
    background: linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880);
    color: #ffffff;
    display: flex;
    align-items: center;
  }
  .matching-linear-button p {
    font-size: 14px;
    font-weight: 550;
    font-family: "Playfair Display", serif;
  }
  
  .tables-matcing-container {
    width: 100%;
    max-height: 85vh;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex:1;
  }
  .table-match-pricing-contain {
    width: 50%;
    padding: 5px;
  }
  
  .table-matching-table-button{
    display: flex;
    justify-content: space-between;
  }
  .table-match-search-contain {
    margin: 8px 0;
  }
  
  .table-matchPrice-contain {
    background-color: #8b5a5a;
    width: 100%;
    /* height: 60vh;
    overflow: hidden; */
  }
  
  /* ////////////////////////////////////////// */
  /* .matching-Pricing-container {
    display: flex;
    gap: 20px;
    width: 100%;
    height: 80vh;
    margin: auto;
    overflow: auto;
  } */
  /* ///////////////////////////////////////////////////////////// */
  .script-matching-section {
    background-color: #fff;
    height: 100%;
    width: 20%;
    min-width: 200px;
    padding: 12px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .script-match-card {
    padding: 8px 12px;
    border-radius: 8px;
  }
  /* ///////////////////////////////////////////////////////////// */
  /* .tables-matching-Pricing-section {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 5px 8px;
  } */
  
  
  
  
  .table-search-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  
  
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .upload-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 40%;
    position: relative;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .script-matching-section,
    .tables-matching-Pricing-section {
      width: 100%;
    }
  }
  