.user-panel-contanier{
    margin: 25px 25px 0;
}
.user-panel-header{
    margin-bottom: 15px;
    letter-spacing: 2px;
}
.user-panel-filters{
    margin: 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 10px;
}
.user-panel-filters .select-dropdown-control{
    width: 26.5% !important;
}

.user-panel-table-contanier{
    width: 100%;
    background-color: #37373f;
}

