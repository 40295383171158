.login-form-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.login-form-contain {
    background: linear-gradient(45deg, #612f31, #4b284a, #264870);
    color: #ffffff;
    border-radius: 20px;
    padding: 40px 30px;
    width: 100%;
    max-width: 700px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.login-form-header {
    letter-spacing: 1.2px;
}

.login-form-text {
    margin: 5px auto;
    color: #d1d1d1;
}

.from-inputs-controls {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-input-group {
    width: 100%;
}

.login-input-group input {
    width: 90%;
    padding: 15px 20px;
    margin: auto;
    border: none;
    border: 1px solid #a093b8;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    color: black;
    background-color: #ffff;
    letter-spacing: 1px;
}

.login-input-group input::placeholder {
    font-weight: 550;
}

.login-input-group input:focus {
    color: #ffffff;
    background-color: #3b364c;
}

.login-button {
    width: 90%;
    margin: auto;
    padding: 15px 20px;
    border-radius: 10px;
    background: linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880);
    color: #ffffff;
}

.login-button .button-text {
    font-size: 18px;
}

.login-button .linear-button:hover {
    background: linear-gradient(180deg, #8b5a5a, #6c4c6e, #4a6880);
}

.login-button:hover:disabled {
    background: #d3d3d3 !important;
    cursor: not-allowed !important;
}






/* Media Query for smaller devices */
@media (max-width: 600px) {
    .login-form-contain {
        padding: 20px;
        max-width: 90%;
    }
}