.locator-report-container {
    width: 100%;
}

.locator-leads-report-contain {
    width: 100%;
    margin:16px 0 0 ;
    padding: 0 12px 0;
}
.locator-leads-report-contain span{
    background: linear-gradient(to right, #fed766, #e8b6ea);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 2px;
}
.locator-leads-report-tables-contain{
    margin-top:14px ;
    border: 1.5px solid #37373F;
    width: 100%;
    padding: 14px 20px;
    border-radius: 8px;
}
.leads-locator-timezone-container{
    width: 100%;
    margin-bottom: 15px;
}
.leads-locator-timezone-container h3{
    margin-bottom: 8px;
    letter-spacing: 1.5px;
    color: #e8b6ea;
}