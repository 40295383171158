/* Dashboard Container */
.show-dashboard {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Sections */
.section-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  transition: box-shadow 0.3s ease-in-out;
}

.section-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.section-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.5rem !important;
  color: #333;
}

/* Grid and Stats */
.controls-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

@media (min-width: 768px) {
  .controls-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .controls-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.stat-box {
  text-align: center;
  padding: 18px;
  border-radius: 10px;
  font-weight: 500;
  transition: transform 0.3s ease-in-out;
}

.stat-box:hover {
  transform: translateY(-3px);
}

.blue {
  background: #e3f2fd;
  color: #1e88e5;
}

.green {
  background: #e8f5e9;
  color: #43a047;
}

/* Pricing Table */
.pricing-options {
  margin-bottom: 12px;
  padding: 14px;
  background: #eef2ff;
  border-radius: 10px;
  font-weight: 500;
  transition: background 0.3s ease-in-out;
}

.pricing-options:hover {
  background: #dce4ff;
}

.pricing-table {
  overflow-x: auto;
  margin-top: 14px;
  border-radius: 8px;
}

.pricing-table table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}

.pricing-table th,
.pricing-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.pricing-table th {
  background: #f5f5f5;
  font-weight: bold;
  text-transform: uppercase;
}

.pricing-table tr:hover {
  background-color: #f9f9f9;
}

.preview-box {
  background-color: #ebf8ff; /* equivalent to bg-blue-50 */
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.template-info {
  font-size: 0.875rem;
  color: #4b5563; /* text-gray-600 */
  margin-bottom: 16px;
}

.font-medium {
  font-weight: 500;
}

/* Attachments Section */
.attachments-section {
  background-color: #f9fafb; /* bg-gray-50 */
  border: 1px solid #e5e7eb; /* border-gray-200 */
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.attachments-list {
  margin-top: 8px;
}

/* Email Previews */
.email-preview-section {
  padding: 16px;
  border-radius: 8px;
}

.email-preview-text,
.email-preview-html {
  height: 256px; /* roughly h-64 */
  padding: 16px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  overflow: auto;
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 0.875rem;
  cursor: pointer;
  margin-top: 8px;
}

/* Table Container */
.table-container {
  overflow-x: auto;
}

/* Companies Table, Shows Without Emails, & Shows Sent */
.companies-table,
.shows-without-emails,
.shows-sent {
  margin-top: 16px;
}

/* No data row styling */
.no-data {
  padding: 16px;
  color: #6b7280;
}
