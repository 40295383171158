.callback-leads-agent-container{
    width: 100%;
}
.callback-agent-header{
    letter-spacing: 1px;
}
.callback-agent-header span{
    background: linear-gradient(to right, #fed766, #e8b6ea);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: 700;
}

.callback-agent-table{
    width: 100%;
    margin: 20px 0 0 0;
    max-height: 70vh;
}
.callback-agent-header-container{
    width: 100%;
    background: #37373f;
    padding: 8px 18px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



