.agent-leads-request-container{
    width: 100%;
    border-radius: 12px;
    padding: 8px 16px;
    background-color: #37373F;
    margin: 0 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.agent-leads-table-container{
    width: 100%;
    color: #33333344;
}

.add-leads-button{
    display: flex;
    align-items: center;
    gap: 6px;
    color: #ffffff;
    font-size: 15px !important;
    font-weight: 600;
    background: #e8b6ea;
    padding: 7px 16px;
    border-radius: 8px;
}
.add-leads-button:hover{
    /* background: linear-gradient(#fed766,#e8b6ea); */
    background-color: gray; /* Prevent any hover color changes */
    color: inherit; /* Prevent any hover text color changes */
    cursor: not-allowed;
}

.request-label-contanier{
    display: flex;
    align-items: center;
}

#timezone-select {
    width: 250px;
    padding: 6px 12px;
    font-size: 16px;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: border-color 0.3s ease;
    margin-left: 8px;
  }
  
  #timezone-select:focus {
    border-color: #4f637d; /* Change border color on focus */
  }
  
  #timezone-select option {
    padding: 8px;
    background-color: #fff;
    color: #333;
  }
  