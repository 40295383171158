
/* Center the loader container in the middle of the page */
.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: background overlay */
    width: 100%;
    height: 100%;
    z-index: 9999; /* Ensure the loading screen is on top */
  }
  
  /* Style for the loader (spinner) */
  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
  }
  
  /* Create spin animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Style for the loading text */
  .loading-container span {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }