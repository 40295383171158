* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  background: #1c1c1e;
}

h1,
h2,
h3 {
  font-family: "Playfair Display", serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button{
  border: none;
  cursor: pointer;
}

.message-error {
  color: #d9534f; 
  font-size: 15px; 
  letter-spacing: 1px;
  font-weight: bold;
  margin-top: 5px;
}

.header-message-error {
  color: #d9534f; 
  font-size: 26px; 
  letter-spacing: 1px;
  font-weight: bold;
  margin-top: 5px;
}



button:disabled {
  background-color: #cccccc; /* Light grey background */
  color: #888888; /* Dark grey text */
  border: 1px solid #888888; /* Border color */
  cursor: not-allowed; /* Indicates the button is not clickable */
  opacity: 0.6; /* Reduces opacity to make it look faded */
}

input:disabled {
  background-color: #f2f2f2; /* Light background color */
  color: #888888; /* Dark grey text */
  border: 1px solid #cccccc; /* Light border */
  cursor: not-allowed; /* Indicates the input is not editable */
  opacity: 0.6; /* Reduces opacity */
}

/* Disabled select field style */
select:disabled {
  background-color: #f2f2f2; /* Light background color */
  color: #888888; /* Dark grey text */
  border: 1px solid #cccccc; /* Light border */
  cursor: not-allowed; /* Indicates the select is not clickable */
  opacity: 0.6; /* Reduces opacity */
}
