.bugs-container {
  margin-top: 20px;
  max-width: 800px;
}

.bugs-title {
  text-align: center;
  margin-bottom: 20px !important;
  font-weight: bold;
  color: #1976d2;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.bugs-accordion {
  margin-bottom: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  background: linear-gradient(
    45deg,
    rgb(206, 196, 196),
    rgb(139, 90, 90),
    rgb(108, 76, 110),
    rgb(51, 51, 51)
  ) !important;
  color: black;
}

.bugs-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px !important;
}

.bugs-summary-content {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  padding-right: 16px;
}

.bug-name {
  font-weight: bold !important;
  color: white !important;
}

.bug-status,
.bug-date {
  color: #f0f0f0;
  font-size: 14px;
  margin-left: 20px;
}

.bugs-accordion .MuiAccordionSummary-root {
  background: transparent;
}

.bugs-accordion .MuiAccordionDetails-root {
  background-color: white;
  padding: 16px;
  color: black;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
