.answer-section-container {
    width: 100%;
    min-height: 83vh;
    overflow: auto;
    /* border: 1px solid #fff; */
}

.answer-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.save-answer-button {
    padding: 6px 24px;
    border-radius: 8px;
    background: linear-gradient(to right, #fed766, #e8b6ea);
    color: #ee84f1;
}

.save-answer-button p {
    font-size: 20px;
    color: #fff;
}

.header-answer-details {
    width: 100%;
    margin: 8px auto;
    background-color: #37373f;
    padding: 8px 18px;
    border-radius: 10px;
}

.tabs-answer-details {
    width: 100%;
    max-height: 100vh;
    overflow: auto;
    border: 4px solid;
    border-image: linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880) 1;
}