.email-request-sales-container {
    width: 100%;
}

.email-request-sales-header {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 16px;
}

.email-request-sales-table-container{
    width: 100%;
}

.Dialog-card-sales-container{
    width: 96%;
    min-height: 92vh;
    overflow: auto;
    margin: auto auto;
    border-radius: 10px;
    border: 2px solid #efebeb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.Dialog-card-header-sales{
    width: 100%;
    background: #fdf8f5;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dialog-header-text-sales{
    display: flex;
    gap: 18px;
    color: #d44a00;
}

.Dialog-card-contain-sales{
    padding: 14px;
}

.Dialog-card-Details-sales{

}

.dialog-hotels-table-container-sales{
    width: 98%;
    margin: 10px auto;
}