.ShowDataToLocator-section {
    width: 100%;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.show-data-locator-header {
    padding: 6px 16px;
}

.show-data-locator-table {


}