.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 3rem;
  width: 100%;
}

.container p {
  font-size: larger;
  font-weight: 600;
}

.controllersContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  align-items: center;
  gap: 50px;
}

.controller {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 1rem;
}

.controller button {
  background-image: none !important;
}

.fileName {
  font-size: 18px;
  color: white;
}

.fileInfo {
  display: flex;
  align-items: center;
  gap: 30px;
}
